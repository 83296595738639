@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Chopsic';
  src: url('./fonts/Chopsic.otf') format("opentype");
}
@font-face {
  font-family: 'segoe-ui';
  src: url('./fonts/segoe-ui.ttf') format("opentype");
}

html {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
html::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

/* html {
  scroll-snap-type: y mandatory;
}
header {
  scroll-snap-align: center;
}
section {
  scroll-snap-align: center;
  scroll-snap-stop: always;;
  }
.footer {
  scroll-snap-align: center;
} */


/* hero section home page */
.hero-img{
  opacity: 0.2;
}
.hero-img:hover{
  opacity: 0.6;
  transition: 1s;
}
.hero-img:not(:hover){
  transition: 1s;
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-10px);}
	60% {transform: translateY(-10px);}
}

@media screen and (max-width: 1024px) {
  .order1{
    order: 1;
  }
  .order2{
    order: 2;
  }
}

/* bg gradient */
.bg-gradient{
  background-color:hsla(0,0%,0%,1);
  background-image:
  radial-gradient(at 88% 1%, hsla(259,100%,59%,0.21) 0px, transparent 50%),
  radial-gradient(at 0% 35%, hsla(259,100%,59%,0.21) 0px, transparent 50%),
  radial-gradient(at 90% 100%, hsla(240,100%,70%,0.21) 0px, transparent 50%);
}

.font-chopsic{
  font-family: "Chopsic";
}
.font-segoe{
  font-family: 'Segoe UI';
}


.font-inter{
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: auto;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

.font-aclonica{
  font-family: "Aclonica", sans-serif;
  font-weight: auto;
  font-style: normal;
}

.font-montserrat{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: auto;
  font-style: normal;
}

.font-syne{
  font-family: "Syne", sans-serif;
  font-optical-sizing: auto;
  font-weight: auto;
  font-style: normal;
}

@media screen and (min-width: 1024px) {
  .flip-card {
    background-color: transparent;
    width: 190px;
    height: 190px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .flip-card-back {
    /* background-color: #D9D9D9; */
    /* color: white; */
    transform: rotateY(180deg);
    background-image: linear-gradient(to right top, #051937, #5d2e63, #b93d68, #f87047, #ffc100);
  }
}

/* button effect */




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* button animation home page */
.glow-on-hover {
  width: 220px;
  height: 2.5rem;
  border: none;
  outline: none;
  color: #111;
  background: #fff;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 40px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 40px;
}

.glow-on-hover:active {
  color: #fff
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
  top: 0;
  border-radius: 40px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

.artist-hover{
  /* transform: scale(1.1); */
  /* transform-origin: 50% 50%; */
  transition: all 0.3s;
  border-radius: 33px;
  display: block;
  overflow: hidden;

  /* transform: scale(1); */
}
.artist-hover:hover{
  /* transform: scale(1.1); */
  filter: sepia(1);
}